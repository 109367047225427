<template>
  <Layout>
    <PageHeader
      :title="title"
      moda_title="Sugerencia"
      ref="pageForm"
      :isAdmin="true"
    >
      <template>
        <PageForm
          :typeform="typeform"
          @closeModa="closeModa"
          @getData="getData()"
        />
      </template>
    </PageHeader>
    <show
      ref="showDetail"
      :title="titleSupport"
      :description="descriptionSupport"
      :supportId="supportId"
      :userRole="userRole"
      :replies="supportReplies"
      @storeSupportReplies="storeSupportReplies"
    ></show>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <Tooltip>
                <p>
                  En este apartado los usuarios dejarán las dudas que no
                  encuentren en la sección de F.A.Qs.
                </p>
                <p>
                  Puedes responder a sus dudas luego de abrir los detalles del
                  registro.
                </p>
              </Tooltip>
            </div>
            <div class="row mt-4">
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="my-2 me-2">{{ $t('filter.from') }}: </span>
                  <vs-input
                    v-model="filterData.fromDate"
                    type="date"
                  ></vs-input>
                </label>
              </div>
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="my-2 me-2">{{ $t('filter.until') }}: </span>
                  <vs-input v-model="filterData.toDate" type="date"></vs-input>
                </label>
              </div>
              <div class="col-sm-12 col-md-12" v-if="checkAdmins()">
                <label class="d-inline-flex align-items-center w-25">
                  {{ $t('filter.client') }}:
                  <select
                    v-model="filterData.clientId"
                    name="client"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione.."
                    @change="change"
                  >
                    <option disabled selected>
                      {{ $t('helpers.select') }}...
                    </option>
                    <option
                      v-for="(item, index) in clients"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <vs-button type="button" @click="getData()">
                {{ $t('filter.filter') }}
              </vs-button>
              <vs-button
                type="button"
                success
                @click="cancelFilter()"
                :disabled="!Object.entries(filterData).length"
              >
                {{ $t('filter.cancel') }}
              </vs-button>
            </div>
            <div class="row mt-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.show') }}&nbsp;
                    <vs-select v-model="perPage" color="dark" class="shadow-lg">
                      <vs-option
                        v-for="(op, index) in pageOptions"
                        :key="index"
                        :label="op"
                        :value="op"
                        >{{ op }}</vs-option
                      >
                    </vs-select>
                    &nbsp;{{ $t('filter.entries') }}
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center m-2">
                    {{ $t('filter.search') }}:
                    <vs-input
                      v-model="filter"
                      type="search"
                      primary
                      class="shadow-lg"
                    ></vs-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>

            <b-overlay :show="loading" rounded="lg">
              <div class="table-responsive mb-0">
                <b-table
                  :items="tableData"
                  :fields="makeFields()"
                  responsive="sm"
                  :filter-included-fields="filterOn"
                  :filter="filter"
                  :sort-by="sortBy"
                  :sort-desc="sortDesc"
                  v-if="tableData.length"
                >
                  <template #cell(replies)="data">{{
                    data.item.replies.length ? 'Si' : 'No'
                  }}</template>
                  <template #cell(createdAt)="data">
                    <div
                      class="createdAt"
                      @mouseover="handleOver"
                      @mouseleave="handleLeave"
                    >
                      {{
                        moment(data.item.createdAt).format(
                          'DD/MM/YYYY | HH:mm:ss'
                        )
                      }}
                      <span class="badge badge-time bg-primary rounded-pill"
                        >Server time:
                        {{
                          moment
                            .utc(data.item.createdAt)
                            .format('DD/MM/YYYY | HH:mm:ss')
                        }}</span
                      >
                    </div>
                  </template>
                  <template #cell(details)="data">
                    <vs-button success @click="openDetail(data.item)">{{
                      $t('helpers.look')
                    }}</vs-button>
                  </template>
                  <template #cell(readed)>
                    <div class="d-flex justify-content-center">
                      <b-form-checkbox
                        name="readedSupport"
                        id="readedSupport"
                      ></b-form-checkbox>
                    </div>
                  </template>
                </b-table>
              </div>
              <div v-if="!tableData.length" class="my-3 text-center">
                {{ $t('tableReports.noResults') }}
              </div>
            </b-overlay>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <button
                      class="btn btn-secondary mx-2 uil-angle-left"
                      @click="beforePage"
                      :disabled="!skip"
                    ></button>
                    <button
                      class="btn btn-primary mx-2 uil-angle-right"
                      @click="nextPage"
                      :disabled="tableData.length < limit"
                    ></button>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import PageForm from './PageForm.vue';
import Show from './show.vue';
import Swal from 'sweetalert2';
import Tooltip from '../../../components/tooltip.vue';
import { onlyAdmins } from '../../../router/guards/roles';
import { ADMIN_ROLE } from '../../../helpers/CONSTANTS';
import { mapGetters } from 'vuex';

/**
 * Users-table component
 */
export default {
  page: {
    title: 'Soporte',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    PageForm,
    Show,
    Tooltip,
  },
  data() {
    return {
      loading: false,
      typeform: {
        title: '',
        description: '',
      },
      title: this.$t('sidebar.technicalSupport'),
      tableData: [],
      userData: null,
      userRole: null,
      filterData: {},
      currentPage: 1,
      limit: 10,
      titleSupport: '',
      descriptionSupport: '',
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: ['title'],
      sortBy: 'end_date',
      sortDesc: true,
      skip: 0,
      clients: [],
      supportReplies: [],
      supportId: null,
      // ROLES
      readAllRoles: onlyAdmins,
    };
  },
  computed: {
    ...mapGetters({
      userIsClient: 'user/isClient',
    }),
  },
  mounted() {
    // Set the initial number of items
    // this.totalRows = this.items.length;
    // this.getData();
    this.getUserRole();
  },
  methods: {
    async getSupportReplies(supportId) {
      this.supportReplies = [];
      try {
        const { data } = await this.$http.get(`/supports/${supportId}/replies`);

        if (!data.ok) {
          return (this.supportReplies = []);
        }

        this.supportReplies = data.replies;
      } catch (error) {
        console.log('Error obteniendo supports responses', error);
      }
    },
    async storeSupportReplies({ supportId, msg }) {
      const userId = this.userData.id;

      if (!(userId && supportId && msg)) return;

      const { data } = await this.$http.post('supports/add-reply', {
        userId,
        supportId,
        msg,
      });

      if (!data.ok) {
        return Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Error enviando respuesta',
          showConfirmButton: false,
          timer: 2000,
        });
      }

      this.getSupportReplies(supportId);
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Respuesta enviada',
        showConfirmButton: false,
        timer: 2000,
      });
    },
    checkAdmins() {
      return this.readAllRoles.includes(this.userRole);
    },
    async openDetail(item) {
      await this.getSupportReplies(item._id);
      this.supportId = item._id;
      this.titleSupport = item.title;
      this.descriptionSupport = item.description;
      this.$refs.showDetail.modal = true;
    },
    makeFields() {
      const globalFields = [
        {
          key: 'title',

          label: this.$t('technicalSupport.title').toUpperCase(),
          class: 'col-4',
        },
        {
          key: 'userSupport[0].name',

          label: this.$t('betDetail.user').toUpperCase(),
        },
        {
          key: 'userClient[0].name',

          label: this.$t('tableReports.client').toUpperCase(),
        },

        {
          key: 'role[0].name',

          label: this.$t('initSession.role').toUpperCase(),
        },
        {
          key: 'details',

          label: this.$t('table.details').toUpperCase(),
        },
        {
          key: 'replies',
          label: this.$t('tableReports.answered').toUpperCase(),
        },
        {
          key: 'createdAt',

          label: this.$t('tableReports.date').toUpperCase(),
        },
      ];

      const adminFields = [
        // {
        //   key: "readed",
        //   label: "Leído",
        //   class: "text-center",
        // },
      ];

      if (this.userRole === ADMIN_ROLE) {
        const fields = [...globalFields, ...adminFields];

        return fields;
      }

      return globalFields;
    },
    getUserRole() {
      this.userData = this.$store.getters['user/getUserData'];
      this.userRole = this.userData.role.name;

      if (this.userIsClient) {
        this.filterData.clientId = this.userData.client;
      } else if (this.userRole === 'OPERATOR') {
        this.filterData.operatorId = this.userData.operator;
      } else {
        this.getClients();
        // this.getOperator();
      }

      this.getData();
    },
    getData() {
      this.$http
        .get('/supports', {
          params: {
            page: this.currentPage,
            limit: this.limit,
            ...this.filterData,
          },
        })
        .then((response) => {
          this.tableData = response.data.suggestions;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getClients() {
      this.$http
        .get('/clients')
        .then((response) => {
          this.clients = response.data.clients;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    closeModa() {
      this.$refs.pageForm.modal = false;
      this.typeform = {
        title: '',
        description: '',
      };
      this.getData();
    },
    handleOver(e) {
      const element = e.target;
      const badge = element.querySelector('.badge-time');
      if (badge) {
        badge.style.visibility = 'visible';
      }
    },
    handleLeave(e) {
      const element = e.target;
      const badge = element.querySelector('.badge-time');
      if (badge) {
        badge.style.visibility = 'hidden';
      }
    },
    nextPage() {
      this.skip += this.limit;
      this.currentPage++;
      this.getData();
    },
    beforePage() {
      this.skip -= this.limit;
      this.currentPage--;
      this.getData();
    },
    change() {
      this.getData();
    },
    cancelFilter() {
      this.filterData = {};
      this.getUserRole();
    },
  },
  watch: {
    perPage() {
      this.skip = 0;
      this.limit = this.perPage;
      this.getUserRole();
    },
  },
};
</script>

<style scoped>
.createdAt {
  position: relative;
}

.badge-time {
  position: absolute;
  left: 0;
  top: -12px;
  visibility: hidden;
  width: max-content;
}
</style>
