<template>
  <b-modal id="bv-modal-example" v-model="modal" centered size="lg" hide-footer>
    <div class="card">
      <p>
        Titulo: <strong>{{ title }}</strong>
      </p>
      <p>Contenido:</p>
      <div>
        <strong>{{ description }}</strong>
      </div>
      <div v-if="replies.length">
        <div class="d-flex align-items-end flex-column">
          <div v-for="(reply, index) in replies" :key="index">
            <div class="d-flex align-items-end mt-3">
              <div class="text-muted me-2" style="font-size: 12px">
                {{
                  moment.utc(reply.createdAt).format('DD/MM/YYYY | HH:mm:ss')
                }}
              </div>
              <div class="bg-primary text-light py-2 px-4 square-msg">
                {{ reply.msg }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isItForAdmin" class="mt-4">
        <b-form-textarea
          id="textarea"
          v-model="msg"
          placeholder="Responder..."
          rows="4"
          max-rows="4"
          no-resize
        ></b-form-textarea>
        <vs-button
          type="button"
          class="mt-3"
          @click="saveMessage"
          :disabled="msg ? false : true"
          >Enviar</vs-button
        >
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['title', 'description', 'userRole', 'replies', 'supportId'],
  data() {
    return {
      modal: false,
      msg: '',
    };
  },
  computed: {
    ...mapGetters({
      isItForAdmin: 'user/onlyAdminRole',
    }),
  },
  methods: {
    closeModa() {
      this.modal = false;
      this.items = [];
      console.log('Cierra');
      this.$emit('closeModa');
    },
    handleOver(e) {
      const element = e.target;
      const badge = element.querySelector('.badge-time');
      if (badge) {
        badge.style.visibility = 'visible';
      }
    },
    handleLeave(e) {
      const element = e.target;
      const badge = element.querySelector('.badge-time');
      if (badge) {
        badge.style.visibility = 'hidden';
      }
    },
    saveMessage() {
      this.$emit('storeSupportReplies', {
        supportId: this.supportId,
        msg: this.msg,
      });
      this.msg = '';
    },
  },
};
</script>

<style scoped>
.square-msg {
  border-radius: 4px;
  border-top-right-radius: 0px;
  max-width: 400px;
}
.black {
  background: #000;
  color: #fff;
  padding: 4px;
  text-align: center;
}

.red {
  background: #f10;
  color: #fff;
  padding: 4px;
  text-align: center;
}

.green {
  background: #00a429;
  color: #fff;
  padding: 4px;
  text-align: center;
}
</style>
