<template>
  <div class="d-flex text-light">
    <div
      class="circle-info bg-secondary"
      @mouseover="handleOver"
      @mouseleave="handleLeave"
    >
      <span class="p-0 m-0">i</span>
    </div>
    <div
      class="tooltip-content bg-secondary rounded p-2 d-flex justify-content-center"
      ref="tooltipContent"
    >
      <div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return { name: 'ToolTip' };
  },
  methods: {
    handleOver() {
      this.$refs.tooltipContent.classList.add('visible');
    },
    handleLeave() {
      this.$refs.tooltipContent.classList.remove('visible');
    },
  },
};
</script>

<style scoped>
.circle-info {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}

.tooltip-content {
  position: absolute;
  left: 50px;
  z-index: 999;
  min-width: 100px;
  max-width: 800px;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s ease-in;
}

.visible {
  opacity: 1;
  pointer-events: all;
}
</style>
