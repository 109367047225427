<template>
  <div>
    <form @submit.prevent="save()">
      <b-row>
        <b-col xs="12">
          <div class="mb-3">
            <label>Titulo</label>
            <vs-input
              v-model="typeform.title"
              placeholder="Titulo de la sugerencia"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.title.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.title.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.title.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12">
          <div class="mb-3">
            <label>Description de la sugerencia</label>
            <textarea
              rows="4"
              multiple
              v-model="typeform.description"
              type="text"
              class="form-control shadow-lg border-0"
              :class="{
                'is-invalid': typesubmit && $v.typeform.description.$error,
              }"
              style="resize: none"
            />
            <div
              v-if="typesubmit && $v.typeform.description.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.description.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
      </b-row>

      <div class="mb-3 mb-0">
        <div class="d-flex">
          <vs-button type="submit">Guardar</vs-button>
          <vs-button @click="$emit('closeModa')" type="button" success>
            Cancelar
          </vs-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import Swal from 'sweetalert2';

export default {
  props: {
    typeform: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    typeform: {
      title: {
        required,
      },
      description: {
        required,
      },
    },
  },
  methods: {
    save() {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log('error');
      } else {
        this.$http
          .post('supports', {
            ...this.typeform,
            user: JSON.parse(localStorage.getItem('user')).user._id,
          })
          .then(({ data }) => {
            if (!data.ok) {
              return Swal.fire({
                position: 'center',
                icon: 'error',
                title: data.msg,
                showConfirmButton: false,
                timer: 1500,
              });
            }

            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Sugerencia registrada con exito',
              showConfirmButton: false,
              timer: 1500,
            });

            this.$emit('closeModa');
            this.$emit('getData');
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>
